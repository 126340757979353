// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---pages-404-js": () => import("./../../../pages/404.js" /* webpackChunkName: "component---pages-404-js" */),
  "component---pages-about-us-js": () => import("./../../../pages/about-us.js" /* webpackChunkName: "component---pages-about-us-js" */),
  "component---pages-blog-newsroom-js": () => import("./../../../pages/blog-newsroom.js" /* webpackChunkName: "component---pages-blog-newsroom-js" */),
  "component---pages-contact-page-js": () => import("./../../../pages/contact-page.js" /* webpackChunkName: "component---pages-contact-page-js" */),
  "component---pages-index-js": () => import("./../../../pages/index.js" /* webpackChunkName: "component---pages-index-js" */),
  "component---pages-resources-js": () => import("./../../../pages/resources.js" /* webpackChunkName: "component---pages-resources-js" */),
  "component---src-templates-blog-article-blog-article-js": () => import("./../../../src/templates/BlogArticle/BlogArticle.js" /* webpackChunkName: "component---src-templates-blog-article-blog-article-js" */),
  "component---src-templates-profile-article-blog-article-js": () => import("./../../../src/templates/ProfileArticle/BlogArticle.js" /* webpackChunkName: "component---src-templates-profile-article-blog-article-js" */),
  "component---src-templates-resource-article-blog-article-js": () => import("./../../../src/templates/ResourceArticle/BlogArticle.js" /* webpackChunkName: "component---src-templates-resource-article-blog-article-js" */)
}

